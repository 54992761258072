import React from 'react'
import Footer from '../containers/footer'
import { Layout, Section, CTABanner } from 'sparkol-web-component-library'
import { graphql, useStaticQuery, Link } from 'gatsby'
import Image from 'gatsby-image'
import util from 'sparkol-web-component-library/src/helpers/util'
import '../styles/pageNotFound.scss'

const PageNotFound = () => {
  const { contentfulSiteSettings, pageNotFoundQuery } = useStaticQuery(graphql`
    query {
      contentfulSiteSettings {
        hubSpotCode
        icon {
          fixed {
            src
          }
        }
      }
      pageNotFoundQuery: file(relativePath: { eq: "kraken.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const CTABannerData = {
    title: 'Start your 7 day free trial today!',
    body: 'Instant access, no credit card, no risk.',
    image: {
      fluid: {
        src: 'https://www.videoscribe.co/App_Themes/VideoScribe/img/icon-videoscribe-logo-shapes.png'
      }
    },
    button: {
      caption: 'Get Started',
      style: 'outline',
      type: 'reset',
      tag: 'a',
      url: '/sign-up'
    }
  }

  return (
    <>
      <Layout title="Videoscribe - 404" siteConfig={contentfulSiteSettings}>
        <Section>
          <div className="page-not-found">
            <h1> Aaaaaargh!</h1>
            <h2> Here be krakens.</h2>
            <p className="page-not-found__description">
              The page
              <strong>
                <em className="page-not-found__path">{util.getPath()}</em>
              </strong>
              does not exist. It&apos;s possible that the sea monsters have eaten it.
            </p>
            <Link href="/">Escape back to the home page</Link>
            <div className="page-not-found__image">
              <Image fluid={pageNotFoundQuery.childImageSharp.fluid} />
            </div>
          </div>
        </Section>
        <Section>
          <CTABanner {...CTABannerData} />
        </Section>
        <Footer />
      </Layout>
    </>
  )
}

export default PageNotFound
